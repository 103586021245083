import { Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _isHeaderDisplayed = signal<boolean>(false);

  constructor() {}

  updateHeaderDisplay(isDisplayed: boolean): void {
    this._isHeaderDisplayed.set(isDisplayed);
  }

  isHeaderDisplayed(): Signal<boolean> {
    return this._isHeaderDisplayed.asReadonly();
  }
}
